<style>
@media (max-width: 1024px) {
  .sm-spacer {
    margin-top: .5rem;
  }
}
</style>

<script>
import { VclCode } from 'vue-content-loading';
import { HalfCircleSpinner } from 'epic-spinners'
import {get_acsrf_token} from "@/methods";
import {mapGetters} from "vuex";

export default {
  components: {
    VclCode,
    HalfCircleSpinner
  },
  props: {

  },
  computed: {
    ...mapGetters(['getDTLocale']),
  },
  methods: {
    handleError: function(error) {
      console.log(`[ERROR] ${error}`);
      this.error = true;
    },
    async saveProfile(cftools_id) {
      this.inProgress = true;
      let refTag = `name:${cftools_id}`;
      let name = this.$refs[refTag][0].value;

      let events = [];
      this.$el.querySelectorAll(`.collector-events-${cftools_id}`).forEach((e) => {
        e = e.firstChild;
        if(e.checked) {
          events.push(e.name);
        }
      });

      let channels = [];
      this.$el.querySelectorAll(`.collector-channels-${cftools_id}`).forEach((e) => {
        e = e.firstChild;
        if(e.checked) {
          channels.push(e.name);
        }
      });

      try {
        let acsrf_token = await get_acsrf_token();
        let payload = {
          acsrf_token: acsrf_token,
          cftools_id: cftools_id,
          name: name,
          events: events,
          channels: channels
        };
        let response = await fetch(process.env.VUE_APP_ROOT_API + `v1/watchers/profile`, {
          method: 'PATCH',
          body: JSON.stringify(payload),
          credentials: 'include'
        });
        if(response.ok) {
          await this.getProfiles();
          this.$toast.success(this.$t('watchers.list.save.success'));
        } else {
          if(response.status === 429) {
            this.$toast.warning(this.$t('error.server.ratelimit.short'));
          } else if(response.status === 403) {
            this.$toast.error('Forbidden');
          } else
            throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
        }
      } catch (error) {
        console.log(`[ERROR] ${error}`);
        this.$toast.error(this.$t('error.server.generic.message'));
      }
      this.inProgress = false;
    },
    async deleteProfile(cftools_id) {
      this.inProgress = true;

      try {
        let acsrf_token = await get_acsrf_token();
        let payload = {
          acsrf_token: acsrf_token,
          cftools_id: cftools_id
        };
        let response = await fetch(process.env.VUE_APP_ROOT_API + `v1/watchers/profile`, {
          method: 'DELETE',
          body: JSON.stringify(payload),
          credentials: 'include'
        });
        if(response.ok) {
          await this.getProfiles();
        } else {
          if(response.status === 429) {
            this.$toast.warning(this.$t('error.server.ratelimit.short'));
          } else if(response.status === 403) {
            this.$toast.error('Forbidden');
          } else
            throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
        }
      } catch (error) {
        console.log(`[ERROR] ${error}`);
        this.$toast.error(this.$t('error.server.generic.message'));
      }
      this.inProgress = false;
    },
    async getProfiles() {
      try {
        let response = await fetch(process.env.VUE_APP_ROOT_API + `v1/watchers/profile`, {
          method: 'GET',
          credentials: 'include'
        });
        if(response.ok) {
          let data = await response.json();
          this.profiles = data.profiles;
          this.ready = true;
        } else {
          throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
        }
      } catch (error) {
        this.handleError(error);
        console.log(`[ERROR] ${error}`);
      }
    },
  },
  async created() {
    await this.getProfiles()
  },
  data() {
    return {
      ready: false,
      error: false,
      profiles: [],
      inProgress: false
    }
  }
};
</script>

<template>
  <div>
    <template v-if="ready">
      <div class="row justify-content-center">
        <div class="col-lg-6 col-sm-12">
          <div v-if="profiles.length">
            <div role="tablist">
              <b-card no-body class="shadow-none settings-collapse" v-for="profile in profiles" :key="profile.cftools_id">
                <a v-b-toggle="profile.cftools_id" href="javascript: void(0);">
                  <b-card-header header-tag="header" role="tab">
                    <div class="d-inline-block">
                      <h3 class="m-0 text-code text-uppercase">
                        {{profile.identifier}}
                      </h3>
                    </div>
                    <div class="d-inline-block float-right bv-d-md-down-none">
                      <div class="btn btn-sm btn-dark">
                        {{ $t('watchers.list.expand') }}
                      </div>
                    </div>
                  </b-card-header>
                </a>

                <!-- General -->
                <b-collapse :id="profile.cftools_id" accordion="profiles" role="tabpanel">
                  <b-card-body>
                    <b-card-text>
                      <div class="row">
                        <div class="col-12">
                          <label>
                            {{ $t('watchers.list.target.label') }}
                          </label>
                          <router-link target="_blank"  :to="{name: 'profile', params: {cftools_id: profile.cftools_id}}">
                            {{ profile.cftools_id }}
                          </router-link>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-12">
                          <div class="form-group pb-0 mb-0">
                            <label>
                              {{ $t('watchers.list.name.label') }}
                            </label>
                            <input
                                :ref="'name:'+profile.cftools_id"
                                :value="profile.identifier"
                                type="text"
                                class="form-control"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="row mt-2">
                        <div class="col-12">
                          <label>
                            {{ $t('watchers.list.created_at') }}
                          </label>
                          {{ $d(parseDate(profile.created_at), 'datetime', getDTLocale()) }}
                        </div>
                      </div>
                      <div class="row mt-2" v-if="profile.expires_at !== null">
                        <div class="col-12">
                          <label>
                            {{ $t('watchers.list.expires_at') }}
                          </label>
                          {{ $d(parseDate(profile.expires_at), 'datetime', getDTLocale()) }}
                        </div>
                      </div>
                      <div class="row mt-2" style="display: none;">
                        <div class="col-lg-9 col-sm-12">
                          <div class="row">
                            <div class="col">
                              <label>
                                {{ $t('watchers.list.events.label') }}
                              </label>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col">
                              <!-- User -->
                              <b-form-checkbox
                                  value="true"
                                  unchecked-value="false"
                                  name="user.join"
                                  class="text-code"
                                  :class="'collector-events-'+profile.cftools_id"
                                  :checked="profile.events.includes('user.join')"
                              >
                                user.join
                              </b-form-checkbox>
                            </div>
                            <div class="col">
                              <b-form-checkbox
                                  value="true"
                                  unchecked-value="false"
                                  name="user.leave"
                                  class="text-code"
                                  :class="'collector-events-'+profile.cftools_id"
                                  :checked="profile.events.includes('user.leave')"
                              >
                                user.leave
                              </b-form-checkbox>
                            </div>
                            <div class="col">
                              <b-form-checkbox
                                  value="true"
                                  unchecked-value="false"
                                  name="user.kicked"
                                  class="text-code"
                                  :class="'collector-events-'+profile.cftools_id"
                                  :checked="profile.events.includes('user.kicked')"
                              >
                                user.kicked
                              </b-form-checkbox>
                            </div>
                            <div class="col"></div> <!-- Keep for proper padding -->
                          </div>
                        </div>
                      </div>
                      <div class="row mt-2" style="display: none;">>
                        <div class="col-lg-9 col-sm-12">
                          <div class="row">
                            <div class="col">
                              <label>
                                {{ $t('watchers.list.channels.label') }}
                              </label>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col">
                              <b-checkbox
                                  value="true"
                                  unchecked-value="false"
                                  name="web"
                                  class="text-code disabled"
                                  :class="'collector-channels-'+profile.cftools_id"
                                  :checked="true"
                                  :disabled="true"
                                  readonly="readonly"
                              >
                                {{ $t('watchers.list.channels.web') }}
                              </b-checkbox>
                            </div>
                            <div class="col"></div> <!-- Keep for proper padding -->
                          </div>
                        </div>
                      </div>
                      <div class="row mt-2">
                        <div class="col-lg-3 col-sm-12">
                          <button class="btn btn-block btn-outline-danger" v-on:click="deleteProfile(profile.cftools_id)">
                            {{ $t('watchers.list.delete') }}
                          </button>
                        </div>
                        <div class="col"></div>
                        <div class="col-lg-3 col-sm-12 pull-right sm-spacer">
                          <button class="btn btn-block btn-success" v-on:click="saveProfile(profile.cftools_id)" :disabled="inProgress" :class="{'disabled': inProgress}">
                            <half-circle-spinner
                                v-if="inProgress"
                                :animation-duration="1200"
                                :size="24"
                                class="align-middle d-inline-block"
                            />
                            <template v-if="!inProgress">
                              {{ $t('watchers.list.save.button') }}
                            </template>
                          </button>
                        </div>
                      </div>
                    </b-card-text>
                  </b-card-body>
                </b-collapse>
              </b-card>
            </div>
          </div>
          <div class="row" v-else>
            <div class="col justify-content-center">
              <div  class="info-component text-center align-middle mt-auto mb-auto">
                <div class="info-component-space">
                  <i class="fad fa-empty-set info-component-icon"></i>
                </div>
                <div class="row" style="margin-top: 20px;">
                  <div class="col-lg-12">
                    <h3 v-b-tooltip.hover title="Wow such empty 👻">{{$t('watchers.list.empty.title')}}</h3>
                    <h5 class="text-muted">{{$t('watchers.list.empty.message')}}</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-else-if="error">
      <div class="row">
        <div class="col-lg-12 col-sm-12">
          <div class="card border border-danger">
            <div class="card-header bg-transparent border-danger">
              <h5 class="my-0 text-danger">
                <i class="far fa-exclamation-circle mr-3"></i>
                {{$t('error.server.generic.title')}}
              </h5>
            </div>
            <div class="card-body pt-0">
              <h5 class="card-title mt-0"> {{$t('error.server.generic.component')}}</h5>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="row">
        <div class="col-lg-11">
          <!-- Content row one -->
          <div class="row">
            <div class="col">
              <div class="card">
                <div class="card-body">
                  <div class="row">
                    <div class="col-lg-4">
                      <VclCode :speed="1" primary="#FFFFFF" secondary="#bdbdbd" class="mt-4"></VclCode>
                    </div>
                    <div class="col-lg-4">
                      <VclCode :speed="1" primary="#FFFFFF" secondary="#bdbdbd" class="mt-4"></VclCode>
                    </div>
                    <div class="col-lg-4">
                      <VclCode :speed="1" primary="#FFFFFF" secondary="#bdbdbd" class="mt-4"></VclCode>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <div class="card">
                <div class="card-body">
                  <div class="row">
                    <div class="col-lg-4">
                      <VclCode :speed="1" primary="#FFFFFF" secondary="#bdbdbd" class="mt-4"></VclCode>
                    </div>
                    <div class="col-lg-4">
                      <VclCode :speed="1" primary="#FFFFFF" secondary="#bdbdbd" class="mt-4"></VclCode>
                    </div>
                    <div class="col-lg-4">
                      <VclCode :speed="1" primary="#FFFFFF" secondary="#bdbdbd" class="mt-4"></VclCode>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>
